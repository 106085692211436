import { defaultIconTransformations } from '../icon/defaults.mjs';
const defaultIconSizeCustomisations = Object.freeze({
  width: null,
  height: null
});
const defaultIconCustomisations = Object.freeze({
  // Dimensions
  ...defaultIconSizeCustomisations,
  // Transformations
  ...defaultIconTransformations
});
export { defaultIconCustomisations, defaultIconSizeCustomisations };