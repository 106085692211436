export { defaultIconCustomisations, defaultIconSizeCustomisations } from './customisations/defaults.mjs';
export { mergeCustomisations } from './customisations/merge.mjs';
export { toBoolean } from './customisations/bool.mjs';
export { flipFromString } from './customisations/flip.mjs';
export { rotateFromString } from './customisations/rotate.mjs';
export { matchIconName, stringToIcon, validateIconName } from './icon/name.mjs';
export { mergeIconData } from './icon/merge.mjs';
export { mergeIconTransformations } from './icon/transformations.mjs';
export { defaultExtendedIconProps, defaultIconDimensions, defaultIconProps, defaultIconTransformations } from './icon/defaults.mjs';
export { makeIconSquare } from './icon/square.mjs';
export { getIconsTree } from './icon-set/tree.mjs';
export { parseIconSet, parseIconSetAsync } from './icon-set/parse.mjs';
export { validateIconSet } from './icon-set/validate.mjs';
export { quicklyValidateIconSet } from './icon-set/validate-basic.mjs';
export { expandIconSet } from './icon-set/expand.mjs';
export { minifyIconSet } from './icon-set/minify.mjs';
export { getIcons } from './icon-set/get-icons.mjs';
export { getIconData } from './icon-set/get-icon.mjs';
export { convertIconSetInfo } from './icon-set/convert-info.mjs';
export { iconToSVG } from './svg/build.mjs';
export { mergeDefsAndContent, splitSVGDefs, wrapSVGContent } from './svg/defs.mjs';
export { replaceIDs } from './svg/id.mjs';
export { calculateSize } from './svg/size.mjs';
export { encodeSvgForCss } from './svg/encode-svg-for-css.mjs';
export { trimSVG } from './svg/trim.mjs';
export { prettifySVG } from './svg/pretty.mjs';
export { iconToHTML } from './svg/html.mjs';
export { svgToData, svgToURL } from './svg/url.mjs';
export { cleanUpInnerHTML } from './svg/inner-html.mjs';
export { getSVGViewBox } from './svg/viewbox.mjs';
export { buildParsedSVG, convertParsedSVG, parseSVGContent } from './svg/parse.mjs';
export { colorKeywords } from './colors/keywords.mjs';
export { colorToString, compareColors, stringToColor } from './colors/index.mjs';
export { getIconCSS, getIconContentCSS } from './css/icon.mjs';
export { getIconsCSS, getIconsContentCSS } from './css/icons.mjs';
export { mergeIconProps } from './loader/utils.mjs';
export { getCustomIcon } from './loader/custom.mjs';
export { searchForIcon } from './loader/modern.mjs';
export { loadIcon } from './loader/loader.mjs';
export { getEmojiSequenceFromString, getUnqualifiedEmojiSequence } from './emoji/cleanup.mjs';
export { convertEmojiSequenceToUTF16, convertEmojiSequenceToUTF32, getEmojiCodePoint, getEmojiUnicode, isUTF32SplitNumber, mergeUTF32Numbers, splitUTF32Number } from './emoji/convert.mjs';
export { getEmojiSequenceKeyword, getEmojiSequenceString, getEmojiUnicodeString } from './emoji/format.mjs';
export { parseEmojiTestFile } from './emoji/test/parse.mjs';
export { getQualifiedEmojiVariations } from './emoji/test/variations.mjs';
export { findMissingEmojis } from './emoji/test/missing.mjs';
export { createOptimisedRegex, createOptimisedRegexForEmojiSequences } from './emoji/regex/create.mjs';
export { prepareEmojiForIconSet, prepareEmojiForIconsList } from './emoji/parse.mjs';
export { findAndReplaceEmojisInText } from './emoji/replace/replace.mjs';
export { camelToKebab, camelize, pascalize, snakelize } from './misc/strings.mjs';
export { commonObjectProps, compareObjects, unmergeObjects } from './misc/objects.mjs';
export { sanitiseTitleAttribute } from './misc/title.mjs';
import './css/common.mjs';
import './css/format.mjs';
import 'debug';
import './emoji/data.mjs';
import './emoji/test/components.mjs';
import './emoji/regex/tree.mjs';
import './emoji/regex/base.mjs';
import './emoji/regex/numbers.mjs';
import './emoji/regex/similar.mjs';
import './emoji/test/similar.mjs';
import './emoji/test/name.mjs';
import './emoji/test/tree.mjs';
import './emoji/replace/find.mjs';